<template>
  <div class="flex flex-col flex-1">
    <div class="flex items-baseline mb-8">
      <h4 class="inline text-md font-bold lg:whitespace-nowrap mx-3">Case Counts</h4>
      <p class="text-sm text-gray-500 lg:px-8 lg:w-4/5">
        Each case count curve displays the trailing 12-month case count trend. The current value used in determining the
        risk rating
        in the space is on the right hand side of each line.
        <span>
          <a target="_blank" class="text-xs font-medium text-pink-600 underline hover:no-underline"
            :href="learnMoreUrl">
            Learn more
          </a>
        </span>
      </p>
      <!-- <p class="text-xs mr-2 text-gray-500" v-if="updatedDate">Last updated <span class="">{{ updatedDate }}</span></p> -->
    </div>
    <div class="lg:grid lg:grid-cols-2 lg:gap-2 2xl:grid-cols-4 2xl:gap-4">
      <div v-for="(data, index) in dataToPlot" :key="data[0].name" class="relative flex">
        <dashboard-case-count-graph :series="data" />
        <div class="seperator" v-if="index !== dataToPlot.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import DashboardCaseCountGraph from './DashboardCaseCountGraph.vue';

import { Pathogen } from '@/network/models/ParticleOneBackgroundPathogenData';
import buildingApiService from '@/network/services/building';

export default {
  name: 'DashboardCommunityDataCard',
  components: {
    DashboardCaseCountGraph,
  },
  props: {
    updatedDate: {
      type: String
    },
    learnMoreUrl: {
      type: String
    },
    backgroundInfection: {
      type: String
    }
  },
  computed: {
    dataToPlot() {
      if (!this.pathogenData.data) return [];

      let plotData = Object.entries(this.pathogenData.data).map((pathogen) => ({
        name: pathogen[1].display_name,
        data: pathogen[1].case_counts.map((obj) => obj.case_count),
      }));
      plotData = plotData.map(obj => [obj].flat());
      return plotData;
    }
  },
  setup() {
    const store = useStore();
    const location_id = ref(null);

    watch(store.state.dashboard, () => {
      if (store.state.dashboard.selectedBuilding) {
        location_id.value = store.state.dashboard.selectedBuilding.location_id;
      }
    }, { deep: true });

    const pathogenData = reactive({
      ...buildingApiService.getPathogenData([Pathogen.Covid, Pathogen.Rhino, Pathogen.Flu, Pathogen.RSV], location_id)
    });

    return {
      pathogenData
    };
  }
};
</script>

<style>
.seperator {
  height: 100%;
  width: 1px;
  background: #DBE2E6;
  margin-left: auto;
}
</style>
