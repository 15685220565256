import { ParticleOnePathogenData } from './ParticleOnePathogenData';

/* These are the supported pathogens so far */
export enum Pathogen {
  Covid = 'covid',
  Rhino = 'rhino',
  Flu = 'flu',
  RSV = 'rsv',
}

/**
 * Maps a pathogen key (e.x - [covid, rhino, 'flu', 'rsv'] to it's data
 */
export type ParticleOneBackgroundPathogenData = Record<Pathogen, ParticleOnePathogenData>;